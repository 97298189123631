import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VueMeta from "vue-meta";

Vue.use(Buefy, {
  defaultIconPack: "syntho",
});

Vue.use(VueAxios, axios);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

Vue.config.productionTip = false;


//
//let apiUrl = process.env.VUE_APP_API_URL;
// prod settings
axios.defaults.baseURL = "https://greviews.syntho.dev/api";

Vue.prototype.$env = {
  siteTitle: "Salex",
  siteUrl: "https://greviews.syntho.dev/",
  shareImg: "/img/share.jpg",
  api: "https://greviews.syntho.dev/api",
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
