<template>
  <div>
    <b-field label="Business Name">
      <b-input
        v-model="businessName"
        placeholder="Enter business name"
      ></b-input>
    </b-field>
    <b-button @click="getReviews" type="is-primary">Get Reviews</b-button>

    <!-- <div>{{businessName}}</div> -->
    <div class="mt-5" v-if="reviews.length > 0">
      <h2>Reviews:</h2>
      <div class="review-list">
        <div class="review-item" v-for="review in reviews" :key="review.id">
          <p>{{ review.text }}</p>
          <p class="is-size-4 mt-3">
            <b-icon icon="star" type="is-warning" /> {{ review.rating }}
            <span class="review-author">by {{ review.author_name }}</span>
          </p>
          <div class="mt-4">
            <b-button @click="requestRemoval(review)" type="is-primary"
              >Request Removal</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import toast from "@/mixins/toast";
export default {
  data() {
    return {
      reviews: [],
      maxStars: 6,
    };
  },
  mixins: [toast],
  computed: {
    ...mapState("users", ["user"]),
    ...mapGetters(['getBusinessName']),
    businessName: {
      get() {
        return this.getBusinessName;
      },
      set(value) {
        this.updateBusinessName(value);
      },
    },
  },
  methods: {
    ...mapActions(['updateBusinessName']),
    showModal(modal) {
      console.log("showing modal");
      this.$store.commit("modals/showModal", modal);
    },
    async getReviews() {
      this.loading = true;
      this.error = null;
      this.reviews = [];

      if(!this.businessName){
        this.toast(
              "Please enter a business name",
              "is-danger"
            );
        this.loading = false;
        return;
      }

      //set the business name in the store
        this.$store.commit("setBusinessName", this.businessName);

        //fetch reviews

      try {
        const response = await this.axios.post(
          "https://syntho.dev/api/greviews/fetch-reviews.php",
          {
            input: this.businessName,
            maxStars: this.maxStars,
          }
        );
        this.reviews = response.data;
        console.log(response.data);
      } catch (error) {
        this.error = "Error fetching reviews. Please try again.";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    //post request to add a new review
    postRequest(review) {
      this.axios
        .post(`${this.$env.api}/requests/manage_requests.php`, {
          message: review.text,
          rating: review.rating,
          author: review.author_name,
          business: this.businessName,
          token: localStorage.GReviewToken,
          action: "addItem",
        })
        .then((response) => {
          //this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
            this.$emit("didAdd");
          } else {
            this.toast(response.data.message, "is-danger");
            if(response.data.message === "Request already exists!"){
              this.$router.push({ name: "reviews" });
            }
          }
        })
        .catch((error) => {
          //this.isLoading = false;
          console.log(error);
        });
      //end post-------------------------------------------------
    },
    requestRemoval(review) {
      if (this.user.isLogged) {
        this.postRequest(review);
      } else {
        this.toast("You need to be logged in to request removal", "is-danger");
      showModal("login");
      }
    },
  },
};
</script>

<style scoped>
.review-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.review-item {
  margin: 10px;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.review-author {
  font-size: 1.2rem;
  font-style: italic;
  text-transform: capitalize;
}
</style>
