<template>
  <section>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Email</p>
        </header>
        <section class="modal-card-body">
          <b-field
            v-if="form.visible"
            label="Paste code here:"
            :type="form.code.status"
            :message="form.code.message"
          >
            <b-input
              type="code"
              v-model="form.code.data"
              placeholder="Your code"
              required
            ></b-input>
          </b-field>
          <b-message type="is-info" v-if="!form.result">
            If you didn't receive your activation code,<br/>please check
            your spam folder or <br/> <a class="tlink" @click="resendEmail()">RESEND</a> the
            verification email.
          </b-message>
          <b-message :type="form.status" v-if="form.result">
            {{ form.message }}
          </b-message>
          <div v-if="!form.visible" class="has-text-centered">
            <button
              class="button is-primary"
              type="button"
              @click="openLogin()"
            >
              Login Now
            </button>
          </div>
        </section>
        <footer class="modal-card-foot" v-if="form.visible">
          <button class="button is-primary" @click="onSubmit()">
            Confirm
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      form: {
        code: {
          data: "",
          status: "",
          message: ""
        },        
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    onSubmit() {
      if (this.formCheck("code")) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("user/edituser.php", {
            action: "confirm",
            token: localStorage.estoken,
            code: this.form.code.data
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
              this.toast(response.data.message, "is-success");
              this.$store.dispatch("confirm", response.data);
              this.closeModal();
            } else {
              this.form.status = "is-danger";
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    formCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].message = "";
        this.form[e].status = "is-success";
        return true;
      }
    },
    closeModal() {
      this.form.code.data = "";
      this.form.code.status = "";
      this.form.code.message = "";     
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.$store.commit("hide", "confirm");
    },
    toast(message, type) {
      this.$buefy.toast.open({
        position: "is-bottom",
        message: message,
        type: type
      });
    },
    resendEmail() {
      this.isLoading = true;
      //begin post --------------------------------------------
      this.axios
        .post("user/resend.php", {
          token: localStorage.estoken,
          action: "resend"
        })
        .then(response => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
          } else {
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
        });
      //end post-------------------------------------------------
    }
  },
  computed: {
    isActive: {
      get: function() {
        return this.$store.state.modals.confirm;
      },
      // setter
      set: function() {
        this.$store.commit("hide","confirm");
        return false;
      }
    }
  },
  watch: {
    isActive: function() {
      this.form.code.data = "";
      this.form.code.status = "";
      this.form.code.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.form.visible = true;
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content:center;
}
</style>
