<template>
  <section>
    <section class="section container">
    <div class="columns">
      <div class="column is-two-fifths header-title-wraper">
        <div>
        <div class="header-title">Manage Your Reviews</div>
        <div class="header-subtitle">your reputation at your fingertips</div>
        <div class="buttons-wraper has-text-left mt-5" v-if="!user.isLogged">
          <button class="button is-success" @click="showModal('register')">Sign Up</button> <button class="button is-primary" @click="showModal('login')">Log In</button>
        </div>
        <div class="buttons-wraper has-text-left mt-5" v-if="user.isLogged">
          <button @click="goTo('reviews')" class="button is-success">Your Reviews</button>
        </div>
        </div>
      </div>
      <div class="column">
        <img src="img/gs_header.svg" width="100%" alt="">
      </div>
    </div>
    </section>
    <section class="section container has-bg-white mt-6">
      <div class="section-wraper">
        <div class="section-title mb-6">Why should you use G-Reviews?</div>
      <div class="columns">      
        <div class="column has-text-centered">
          <img src="img/gs_manage.svg" width="80%" alt="">
          <div class="content">
            <p class="title is-4 mb-4">Easily Managed</p>
              A simple and yet functional interface will allow you to manage your reviews.
          </div>
        </div>
        <div class="column has-text-centered">
          <img src="img/gs_access.svg" width="80%" alt="">
          <div class="content">
            <p class="title is-4 mb-4">Controlled Reputation</p>
              Controll the trust and reputation that users are expressing.  
          </div>
        </div>
        <div class="column has-text-centered">
          <img src="img/gs_stats.svg" width="80%" alt="">
          <div class="content">
            <p class="title is-4 mb-4">Monitoring</p>
              Our system is providing a easy interface for checking new reviews.
          </div>
        </div>
      </div>
    </div>
    </section>

        <section class="section container mt-6">
          <div class="section-wraper">
      <div class="section-title mb-6">Try it Yourself!</div>

      <GetReviewsSample />
          </div>

    </section>

  </section>
</template>

<script>

import GetReviewsSample from "@/components/general/GetReviewsSample.vue";

import { mapState } from "vuex";

export default {
  name: "HomeView",
  components: {
    GetReviewsSample,
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    showModal(modal) {
            console.log("showing modal");
            this.$store.commit("modals/showModal", modal);
        },
    goTo(to){
      this.$router.push({ path: "/"+to });
    }
  },
  computed: {
    //...mapState("users", ["user"]),
    ...mapState({
      user: (state) => state.users.user,
      //lang: (state) => state.lang,
      //isEditingID: (state) => state.adverts.advert.isEditingID,
      //locations: (state) => state.locations,
    }),
  }
};
</script>
<style scoped>
.header-title-wraper {
  align-items: center;
  display: flex;
}
.header-title {
  text-transform: uppercase;
  font-size: 2.7rem;
  text-align: left;
  font-weight: bold;
  font-family: 'Barlow Semi Condensed', sans-serif;
}
.header-subtitle{
  text-transform: uppercase;
  font-size: 1.4rem;
  text-align: left;
  font-family: 'Barlow', sans-serif;
}

.section-title {
  font-size: 2rem;
  text-align: left;
  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #2e5c9b;
}

.title{
  font-family: 'Barlow Semi Condensed', sans-serif;
}
.section-title:before {
    content: "";
    width: 100px;
    border-bottom: 5px solid #2e5c9b;
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 1;
    }
.section-wraper{
  position: relative;
}
</style>
