<template>
  <div id="app">
    <Navbar />
    <router-view />
    <RestoreModal />
    <RegisterModal />
    <LoginModal />
    <Confirm />
    <Footer />
    <!-- <h1>Negative Review Finder</h1>
    <input v-model="input" placeholder="Enter place name or ID">
    <input v-model.number="maxStars" type="number" min="1" max="5" step="0.1">
    <button @click="fetchReviews">Search</button>
    <div v-if="loading">Loading...</div>
    <div v-else-if="reviews.length">
      <h2>Negative Reviews:</h2>
      <ul>
        <li v-for="review in reviews" :key="review.time">
          {{ review.text }} - {{ review.rating }} stars
        </li>
      </ul>
    </div>
    <div v-else-if="error">{{ error }}</div> -->
  </div>
</template>

<script>
import axios from "axios";

import Navbar from "@/components/navigation/Navbar.vue";
import LoginModal from "@/components/modals/LoginModal.vue";
import RegisterModal from "@/components/modals/RegisterModal.vue";
import RestoreModal from "@/components/modals/RestoreModal.vue";
import Confirm from "@/components/modals/Confirm.vue";
import Footer from "@/components/navigation/Footer.vue";

import confirmMixin from "@/mixins/confirmMixin";

export default {
  name: "Home",
  components: {
    Navbar,
    Footer,
    LoginModal,
    RegisterModal,
    RestoreModal,
    Confirm,
  },
  data() {
    return {
      input: "",
      maxStars: 3,
      reviews: [],
      loading: false,
      error: null,
    };
  },
  beforeMount() {
   console.log("APP BEFORE MOUNT!");
   this.$store.dispatch("getTempToken");
  },
  mounted() {
    console.log("Begin User Check!");
    if (!this.$store.state.users.user.isLogged) {
      this.checkLogged();
      console.log(this.$store.state.users.user.isLogged);
      //console.log(this.$route.name);
    }
  },
  methods: {
    async fetchReviews() {
      this.loading = true;
      this.error = null;
      this.reviews = [];

      try {
        const response = await axios.post(
          "https://syntho.dev/api/greviews/fetch-reviews.php",
          {
            input: this.input,
            maxStars: this.maxStars,
          }
        );
        this.reviews = response.data;
      } catch (error) {
        this.error = "Error fetching reviews. Please try again.";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    checkLogged: function () {
      if (localStorage.GReviewToken) {
        console.log("Validating Token..");
        console.log("Token:" + localStorage.GReviewToken);
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/users/validate.php`, {
            token: localStorage.GReviewToken,
          })
          .then((response) => {
            console.log("RESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit("users/validateUser", response.data.data);
              console.log("Logged!");
              console.log(response.data.data);
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log("token error ",error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
      }
    },
  },
};
</script>

<style lang="scss">
// Import Fontawesome
@import "static/syntho/syntho-style.css";
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;700&family=Barlow+Semi+Condensed:wght@400;600&family=Barlow:wght@400;500&display=swap");
// Set your colors
$primary: #2e5c9b; //#3273dc; //green #215D63; //#00bb8f; //00D1B2
$success: #74b570;
// $modal-background-background-color: #000;
$primary-invert: findColorInvert($primary);
//theme mod
$mydark: #181d29; //#00bb8f; //00D1B2
$mylight: #ebebeb; //#00bb8f; //00D1B2
// $dark: #0d4553;
$filter: #f4f4f4; //#00bb8f; //00D1B2
$sidebar-box-shadow: 0px;
$sidebar-width: 330px;
$body-background-color: #f5f5f5;
$content-heading-color: #000000;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "mydark": (
    $mydark,
    $white,
  ),
  "mylight": (
    $mylight,
    $black,
  ),
  "filter": (
    $filter,
    $white,
  ),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

.header-title-wraper {
  align-items: center;
  display: flex;
}
.header-title {
  text-transform: uppercase;
  font-size: 2.7rem;
  text-align: left;
  font-weight: bold;
  font-family: "Barlow Semi Condensed", sans-serif;
}
.header-subtitle {
  text-transform: uppercase;
  font-size: 1.4rem;
  text-align: left;
  font-family: "Barlow", sans-serif;
}

.section-title {
  font-size: 1.6rem;
  text-align: left;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: #2e5c9b;
}

.title {
  font-family: "Barlow Semi Condensed", sans-serif;
}
//image uploaders ADD, EDIT, PERSONALIZE
.drop {
  padding: 1.5rem 1rem;
  font-size: 12px;
  width: 100%;
}
.imageitem {
  position: relative;
}
.image-tag {
  position: absolute;
  bottom: -7px;
  z-index: 10;
  left: 50%;
  transform: translate(-50%);
}
.button.is-xsmall {
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}
.button.is-xsmall .icon:last-child:not(:first-child) {
  margin-top: 3px;
}
.color-label {
  line-height: 18px;
  font-size: 0.9rem;
  font-weight: bold;
}

.footer {
  padding: 3rem 1.5rem 3rem !important;
}
</style>
